import { useFetchWrapper } from "_helpers";
import { emptyScreen } from "_state";

export { useScreenActions };

function useScreenActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/client`;
  const fetchWrapper = useFetchWrapper();

  return {
    getAll,
    getOne,
    saveOrUpdate,
    generateNew,
    deleteOne,
  };

  function generateNew() {
    return emptyScreen();
  }

  function deleteOne(screen) {
    if (!screen?.id) return Promise.reject(new Error("empty screen id"));
    return fetchWrapper
      .delete(`${baseUrl}/delete/${screen.id}`)
      .then((result) => {
        const { success, id } = result;
        if (success) {
          // const newScreens = [...screens].filter((screen) => screen.id !== id);
          // setScreens(newScreens);
          return id;
        } else {
          throw new Error("failed to delete");
        }
      })
      .catch((error) => error);
  }

  function getAll() {
    return fetchWrapper
      .get(`${baseUrl}/all`)
      .then((result) => {
        if (result.success) return result.screens;
        throw new Error(result.message);
      })
      .catch((error) => {
        throw error;
      });
  }
  function getOne(id) {
    return fetchWrapper
      .get(`${baseUrl}/${id}`)
      .then((result) => {
        if (result.success) return result.screen;
        throw new Error(result.message);
      })
      .catch((error) => {
        throw error;
      });
  }

  async function saveOrUpdate(screen) {
    const action = screen.id ? "update" : "save";
    return fetchWrapper
      .post(`${baseUrl}/${action}`, screen, { body: "json" })
      .then((result) => {
        const { success, screen } = result;
        console.log("saveOrUpdate", { screen });
        if (success) {
          // // splice returned playlist to the list at existing position
          // const newScreens = [...screens];
          // const index = screens.findIndex((item) => item.id === screen.id);
          // if (index > -1) {
          //   newScreens.splice(index, 1, screen);
          // } else {
          //   newScreens.push(screen);
          // }
          // console.log("saveOrUpdate", { newScreens });
          // setScreens(newScreens);
          return { success, message: "screen saved", screen };
        } else {
          throw new Error("something went wrong");
          //return { success: false, message: "something went wrong" };
        }
      });
  }
}
