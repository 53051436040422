import { useEffect, useState } from "react";
//import '../css/Image.scss'

import ImageUploader from "_components/ImageUploader";
import {
  Tabs,
  Tab,
  Card,
  TabContent,
  TabPane,
  Row,
  TabContainer,
  Alert,
} from "react-bootstrap";
import { ImageManager } from "_components/ImageManager";
import { Col } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { useImageActions } from "_actions";
import { SpinnerWithMessage } from "_components/SpinnerWithMessage";
export { Images };

function Images() {
  const imageActions = useImageActions();
  const imageQuery = useQuery({
    queryKey: ["images"],
    queryFn: imageActions.getAll,
  });

  const [key, setKey] = useState("manage");

  useEffect(() => {
    // playlistActions.getAll();
    //imageActions.getAll();
  }, []);
  return (
    <div>
      <Row className="justify-content-md-center">
        <Col md={12}>
          <Card className="mb-3">
            <TabContainer id="tabs-example" activeKey={key}>
              <Card.Header>
                <Card.Title as="h5">Images</Card.Title>
                <Tabs
                  id="image-tabs"
                  onSelect={(k) => setKey(k)}
                  activeKey={key}
                >
                  <Tab eventKey="manage" title="Manage"></Tab>
                  <Tab eventKey="upload" title="Upload"></Tab>
                </Tabs>
              </Card.Header>
              <Card.Body>
                <TabContent>
                  <TabPane eventKey="manage">
                    {imageQuery.data?.length > 0 && (
                      <ImageManager mode='user' images={imageQuery.data}></ImageManager>
                    )}
                    {imageQuery.data?.length == 0 && (
                      <div>There are no images to show. Upload something!</div>
                    )}
                    {imageQuery.isLoading && <SpinnerWithMessage message={'loading'}/>}
                    {imageQuery.error && <Alert variant='warning'>An error occurred gathering images: {imageQuery.error.toString()}</Alert> }
                  </TabPane>
                  <TabPane eventKey="upload">
                    <ImageUploader />
                  </TabPane>
                </TabContent>
              </Card.Body>
            </TabContainer>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
