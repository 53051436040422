
export function Clickable({id, alt, onClick, style, selected, children}) {
 
  return (
    <div
    id={id}
      title={alt}
      onClick={onClick}
      className={`c-draggable c-clickable ${selected ? 'c-clickable__selected' :''}`}
      style={style}
    >
      {children}
    </div>
  );
}
