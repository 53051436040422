import { NavLink } from "react-router-dom";

import { useUserActions } from "_actions";
import { Container } from "react-bootstrap";
import useIsUserRole from "_helpers/hooks/use-is-user-role";

function Nav() {
  const userActions = useUserActions();

  const isLoggedIn = useIsUserRole('any');
  const isAdmin = useIsUserRole('admin');

  // only show nav when logged in
  // if (!auth) return null;

  const accountLinkClass = `nav-item nav-link ${isAdmin ? '' : 'ml-auto'}` 

  return (
    <nav className="navbar navbar-expand navbar-dark bg-dark">
      <Container>
        <div className="navbar-nav w-100 pr-4 pl-4">
          <NavLink exact={true} to="/" className="navbar-brand">
    <img src="https://images.pixelart-exchange.au/uploads/logo-white.png"  height="30" alt=""/>
          </NavLink>
          {isLoggedIn && (
            <>
              <NavLink exact={true} to="/images" className="nav-item nav-link">
                Images
              </NavLink>
              <NavLink
                exact={true}
                to="/playlists"
                className="nav-item nav-link"
              >
                Playlists
              </NavLink>
              <NavLink exact={true} to="/screens" className="nav-item nav-link">
                Screens
              </NavLink>
              {( isAdmin && <NavLink
                exact={true}
                to="/moderation"
                className="nav-item nav-link ml-auto"
              >
                Moderation
              </NavLink>)}

              <NavLink
                exact={true}
                to="/profile"
                className={accountLinkClass}
              > 
                Account Settings
              </NavLink>
              <a onClick={userActions.logout} className="nav-item nav-link">
                Logout
              </a>
            </>
          )}
          {!isLoggedIn && (
            <>
              <NavLink
                exact={true}
                to="/login"
                className="nav-item nav-link  ml-auto"
              >
                Login
              </NavLink>
              <NavLink
                exact={true}
                to="/register"
                className="nav-item nav-link "
              >
                Register
              </NavLink>
            </>
          )}
        </div>
      </Container>
    </nav>
  );
}

export { Nav };
