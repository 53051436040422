import _ from "lodash";
import { useCallback, useState } from "react";
import { Button, Card, CardBody, Col, Form, Row, Stack } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useImageActions } from "../_actions/image.actions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SpinnerWithMessage } from "./SpinnerWithMessage";
import { TagTypeahead } from "./TagTypeahead";
import { typeaheadToValues } from "_helpers/typeahead-utils";

export default function ImageUploader() {
  // the file handles
  const [form, setForm] = useState({
    tags: [],
    isPublic: false,
    isNSFW: false,
  });
  // the img tags generated from them
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadMessage, setUploadMessage] = useState("");
  const imageActions = useImageActions();

  const queryClient = useQueryClient();

  const imageMutator = useMutation({
    mutationFn: ({ form, uploadedImages }) => {
      return imageActions.uploadAll(form, uploadedImages);
    },
    onSuccess: (result) => {
      console.log("uploadSelectedFiles", { result });
      let uploadedImageMap = _.keyBy(uploadedImages, "uid");
      _.each(result, (uploadResult) => {
        uploadedImageMap = _.omit(uploadedImageMap, uploadResult.uid);
      });
      setUploadedImages(_.values(uploadedImageMap));
      setUploadMessage(`${result.length} images uploaded OK.`);
      queryClient.invalidateQueries({ queryKey: ["images"], exact: true });
    },
    onError: (e) => {
      console.log("onError", { e });
    },
  });

  //the completed uploads

  //useEffect(() => {});

  const onDrop = useCallback((acceptedFiles) => {
    dealWithSelectedFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = function (e) {
    e.preventDefault();
    uploadSelectedFiles();
  };
  // const inputChanged = function (e) {
  //   let newValue =
  //     e.target.type === "checkbox" ? e.target.checked : e.target.value;
  //   newValue = e.target.type === "number" ? parseInt(newValue) : newValue;
  //   const newLocalState = _.defaults(
  //     { [e.target.name]: newValue },
  //     { ...form },
  //   );
  //   setForm(newLocalState);
  // };

  const dealWithSelectedFiles = async function (rawFileHandles) {
    const promises = _.map(rawFileHandles, (upload) => {
      return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () =>
          resolve({ uid: upload.path, file: upload, uri: reader.result });
        reader.readAsDataURL(upload);
      });
    });
    let result;
    await Promise.all(promises).then((allDataURIs) => {
      result = _.map(allDataURIs, (resultObj, fileIndex) => {
        return {
          uid: resultObj.uid,
          element: (
            <img alt={resultObj.name} key={fileIndex} src={resultObj.uri} />
          ),
          file: resultObj.file,
        };
      });
    });
    console.log("dealWithSelectedFiles", { result });
    setUploadedImages(result);
    setUploadMessage(`${result.length} images ready to upload`);
  };

  const uploadSelectedFiles = async () => {
    const submittedForm = { ...form, tags: typeaheadToValues(form.tags) };
    imageMutator.mutate({ form: submittedForm, uploadedImages });
  };

  const emptyMessage = _.isEmpty(uploadedImages) ? (
    <p>Drag files here, or click to select files</p>
  ) : (
    <p></p>
  );

  const setToggle = (property) => {
    return () => {
      setForm({ ...form, [property]: !form[property] });
    };
  };
  const setValue = (property) => {
    return (e) => {
      const newValue = e.target.value;
      setForm({ ...form, [property]: newValue });
    };
  };

  return (
    <div>
      <p>
        Upload your images by dropping them into the area below (or click to
        select).
        <br />
        When you have selected them, hit upload.
      </p>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col sm="6">
            <Row className="mb-3">
              <div className="c-filedropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="c-filedropzone-inner">
                  {isDragActive ? <p>Drop the files here ...</p> : emptyMessage}
                  <div className="upload">
                    {_.map(uploadedImages, "element")}
                  </div>
                </div>
              </div>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group controlId="uploadDir">
                  <Form.Label>Tags</Form.Label>
                  <TagTypeahead
                    form={form}
                    setForm={setForm}
                    allowNew={true}
                    local={false}
                  />
                  <Form.Text className="text-muted">
                    Optional tags for these images - can make filtering /
                    finding images easier.
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <Form.Label>Other options</Form.Label>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm="6">
                <Form.Group controlId="isPublic">
                  <Form.Check
                    type="checkbox"
                    id="isPublic"
                    name="isPublic"
                    checked={form.isPublic}
                    onChange={setToggle("isPublic")}
                    label="Public"
                  />
                  <Form.Text className="text-muted">
                    Allow others to use these images (will need moderator
                    approval before they become visible).
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group controlId="isNSFW">
                  <Form.Check
                    type="checkbox"
                    id="isNSFW"
                    checked={form.isNSFW}
                    onChange={setToggle("isNSFW")}
                    label="NSFW"
                  />
                  <Form.Text className="text-muted">
                    These image are not suitable for minors; even if marked
                    public they will only be shown to people who want to see
                    NSFW images.
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <Card className="mb-3">
              <CardBody>
                <Form.Group controlId="exampleForm.attribution">
                  <Form.Label size="sm">Attribution:</Form.Label>
                  <Form.Control
                    size="sm"
                    name="attribution"
                    onChange={setValue("attribution")}
                    value={form.attribution}
                  />
                  <Form.Label size="sm">Source:</Form.Label>
                  <Form.Control
                    size="sm"
                    name="source"
                    onChange={setValue("source")}
                    value={form.source}
                  />
                  <Form.Label size="sm">Source URL:</Form.Label>
                  <Form.Control
                    size="sm"
                    name="sourceUrl"
                    onChange={setValue("sourceUrl")}
                    value={form.sourceUrl}
                  />
                  <Form.Text className="text-muted">
                    Optional values, but useful to let others know where you
                    sourced something.
                  </Form.Text>
                </Form.Group>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="sm-3 u-margin--top-20" controlId="save">
              <div className="text-success mb-3">{uploadMessage}</div>
              <Stack direction="horizontal" gap={3}>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={imageMutator.isLoading}
                >
                  Upload
                </Button>
                {imageMutator.isLoading && (
                  <SpinnerWithMessage message="Upload in progress" />
                )}
                {imageMutator.error && (
                  <div className="text-danger mb-3">
                    {!_.isEmpty(imageMutator.error.error)
                      ? imageMutator.error.error
                      : imageMutator.error.toString()}
                  </div>
                )}
              </Stack>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
