import { useEffect, useState } from "react";
import { DndContext, KeyboardSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';

// import { Draggable } from "./Draggable.jsx";
import { Droppable } from "./Droppable.jsx";
import _ from "lodash";

import { Card, Form, Tab, TabContainer, TabContent, TabPane, Tabs } from "react-bootstrap";
import ImageFilterBar from "_components/ImageFilterBar.jsx";
import { useImageFilter } from "_helpers/hooks/use-image-filter.js";
import { Sortable } from "./Sortable.jsx";
import { Clickable } from "./Clickable.jsx";
import { CustomMouseSensor } from "_helpers/custom-dnd-events.js";

export function ImageSorter(props) {
  const { images, publicImages, selectedImageIds, changeCallback } = props;

  const [selectedIds, setSelectedIds] = useState([]);
  const [filterForm, setFilterForm] = useState({
    isNSFW: 0, tags: []
  });

  const imageFilter = useImageFilter(filterForm);

  const [key, setKey] = useState("yours");
  //const [setMode] = useState("");

  useEffect(() => {
    setSelectedIds(_.uniq(selectedImageIds));
  }, [selectedImageIds]);

  const sensors = useSensors(
    useSensor(CustomMouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleSelectClick(id) {
    const index = selectedIds.findIndex(eid => eid == id);
    const newIds = [...selectedIds]
    if (index > -1 ) {
      newIds.splice(index, 1);
    } else {
      newIds.push(id);
    }
    console.log({selectedIds, newIds})
    setSelectedIds(newIds)
    
      changeCallback(newIds);
  }

  //console.log(`using selectedImageIds: ${selectedImageIds}`);
  console.log({ selectedIds });

  const generateImages = ({ mode, showSelected, isPublic = false }) => {
    let source;
    const uniqueSelected = _.uniq(selectedIds);
    const imageSource = isPublic ? publicImages : images;
    if (showSelected) {
      source = _.compact(_.map(uniqueSelected, (id) => _.find([...publicImages, ...images], { id })));
    } else {
      // show everything, minus the ones we have already got
      // source = _.filter(
      //   imageSource,
      //   (image) => uniqueSelected.indexOf(image.id) === -1,
      //);
      source=imageSource
      // filter bar
      source = source.filter(imageFilter)
    }
    return _.map(source, (image) => {

      const isSelected = uniqueSelected.indexOf(image.id) > -1;

      switch (mode) {
        case Sortable:
          return <Sortable
            id={image.id}
            alt={image.name}
            key={(isPublic ? 'public' : '') + image.id}
          >
            <div style={{ backgroundColor: props.backgroundColor }}>
              <img
                className="c-draggable-image"
                alt={image.name}
                src={image.thumb}
              />
            </div>
            {isSelected && <div
                className="c-removeable c-removeable__selected"
                data-no-dnd="true"
                onClick = {() => handleSelectClick(image.id)}>
                </div>
              }            
          </Sortable>
        case Clickable:
          return <Clickable
            id={image.id}
            alt={image.name}
            key={(isPublic ? 'public' : '') + image.id}
            onClick = {() => {if (!isSelected) handleSelectClick(image.id)}}
            selected = {isSelected}
          >
            <div style={{ backgroundColor: props.backgroundColor }}>
              <img
                className="c-draggable-image"
                alt={image.name}
                src={image.thumb}
              />
            </div>
          </Clickable>
        default:
          break;
      }
    });
  };

  const selectedImages = generateImages({ mode: Sortable, showSelected:true });

  return (
    <>
    <Card className="mb-3">
    <DndContext onDragEnd={handleDragEnd} sensors={sensors}
          collisionDetection={closestCenter}>
          <SortableContext items={selectedIds} strategy={rectSortingStrategy}>
            {/* slected images */}
            <div className="c-draggable-list">
              <div className="c-draggable-list-header">
                <div className="c-draggable-list-label">Selected Images</div>
              </div>
              <Droppable dropId="selectedImages" className="drop-zone">
                <div className="c-draggable-list-items">
                  {selectedImages.length > 0 ? selectedImages : <div className="c-draggable-list-items__empty">Selected images will appear in here.</div>}
                </div>
              </Droppable>
              <Form.Text className="text-muted">
                Drag images to reorder them in the playlist.
              </Form.Text>
            </div>
          </SortableContext>
        </DndContext>
        </Card>
        {/* all images */}
        <Card className="mb-3">
          <TabContainer id="tabs-example" activeKey={key}>
            <Card.Header>
              {/* <Card.Title as="h5">Images</Card.Title> */}
              <Tabs
                id="image-tabs"
                onSelect={(k) => setKey(k)}
                activeKey={key}
              >
                <Tab eventKey="yours" title="Your images"></Tab>
                <Tab eventKey="public" title="Public images"></Tab>
              </Tabs>
            </Card.Header>


            <div className="c-draggable-list">
              <div className="c-draggable-list-header">
                <ImageFilterBar passedState={[filterForm, setFilterForm]} />
              </div>
              <TabContent>
                <TabPane eventKey="yours">
                  <div className="c-draggable-list-items">
                    {generateImages({ mode: Clickable, isPublic: false })}
                  </div>
                </TabPane>
                <TabPane eventKey="public">
                  <div className="c-draggable-list-items">
                    {generateImages({ mode: Clickable, selected: false, isPublic: true })}
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </TabContainer>
        </Card>
        </>

  );

  function handleDragEnd(event) {
    const { active, over } = event;
    let hasUpdated = false;
    let newImageList;
    // if (over?.id === "selectedImages") {
    const draggedId = active.id;
    const alreadySelected = _.includes(selectedIds, draggedId);
    if (alreadySelected) {
      newImageList = selectedIds.slice();
      const oldIndex = newImageList.indexOf(active.id);
      const newIndex = newImageList.indexOf(over.id);
      console.log({ newImageList, over: over?.id, active: active?.id });
      newImageList = arrayMove(newImageList, oldIndex, newIndex);
    } else {
      newImageList = selectedIds.slice();
      newImageList.push(draggedId);
      //   setSelectedIds(newImageList);
    }
    hasUpdated = true;

    //  }
    if (event.over && event.over.id === "allImages") {
      console.log({ event });
      const draggedId = event.active.id;
      const alreadySelected = _.includes(selectedIds, draggedId);
      if (!alreadySelected) return;
      newImageList = _.clone(selectedIds);
      newImageList.splice(selectedIds.indexOf(draggedId), 1);
      //  setSelectedIds(newImageList);
      hasUpdated = true;
    }
    if (hasUpdated) {
      changeCallback(newImageList);
    }
  }

  // function handleDragEnd(event) {
  //   const {active, over} = event;

  //   if (active.id !== over.id) {
  //     setItems((items) => {
  //       const oldIndex = items.indexOf(active.id);
  //       const newIndex = items.indexOf(over.id);

  //       return arrayMove(items, oldIndex, newIndex);
  //     });
  //   }
  // }
}
