import _ from "lodash";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useEffect, useState } from "react";

import { useScreenActions, usePlaylistActions } from "_actions";
import { ConfirmationModal } from "_components/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useUpdateMutation } from "_helpers";
import notify from "_helpers/notify";
import { useNftActions } from "_actions/nft.actions";

const NFT_RANDOM = 'nft_random';
const NFT_COLLECTION = 'nft_collection';

export function ScreenConfigForm({ screen }) {
  const playlistActions = usePlaylistActions();
  const nftActions = useNftActions();
  const screenActions = useScreenActions();
  const playlistQ = useQuery({
    queryKey: ["playlists"],
    queryFn: playlistActions.getAll,
  });
  const nftQ = useQuery({
    queryKey: ["nfts"],
    queryFn: nftActions.getAll,
  });
  const navigate = useNavigate();
  

  const [currentScreen, setCurrentScreen] = useState(screen);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);


  // update as required if input screen changes
  useEffect(() => setCurrentScreen(screen), [screen]);

  const deleteMut = useMutation({
    mutationFn: (screen) => {
      return screenActions.deleteOne(screen);
    },
    onSuccess: () => navigate(`/screens`),
    onError: () => {
      console.log(deleteMut.error);
    },
  });
  //debugger;

  const saveOrUpdate = useUpdateMutation({
    mutationFn: screenActions.saveOrUpdate,
    returnProp: "screen",
    parentKey: ["screens"],
    childKey: ["screens", { id: screen.id || "new" }],
    onSuccess: (id) => navigate(`/screens/${id}`),
    isNew: _.isEmpty(screen.id),
  });

  const inputChanged = function (e) {
    let newValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    newValue = e.target.type === "number" ? parseInt(newValue) : newValue;
    const newScreen = _.defaults(
      { [e.target.name]: newValue },
      { ...currentScreen },
    );
    setCurrentScreen(newScreen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveOrUpdate.mutate(currentScreen);
    // const prom = screenActions.saveOrUpdate(currentScreen);
    // prom.then((result) => {
    //   if (result.success) {
    //     return navigate(`/screens/${result.screen.id}`);
    //   }
    // }
    // )
  };

  const deleteCurrentConfig = () => {
    screenActions
      .deleteOne(screen)
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        return navigate("/screens");
      });
  };


  const copyIdToClipboard = () => {
    navigator.clipboard.writeText(currentScreen.id);
    return notify("Copied to clipboard", "success");
  }

  const body = !_.isEmpty(currentScreen) ? (
    <div>
      <h3>{screen.name || "Setup new screen"}</h3>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col lg={6} md={6}>
            <Form.Group controlId="imagesetId">
              <Form.Label>screen ID</Form.Label>
              
        <InputGroup>
              <Form.Control
                name="id"
                onChange={inputChanged}
                type="text"
                style={{fontStyle: currentScreen.id ? 'normal' : 'italic'}}
                value={currentScreen.id || "Value shown after save"}
                disabled
              />
              <Button variant="light" onClick={copyIdToClipboard}>copy</Button>
              </InputGroup>
              <Form.Text className="text-muted">
                <span hidden={currentScreen.id}>Save screen to generate an ID. </span>
                Paste the ID into WLED to identify this screen when it makes
                requests for images
              </Form.Text>
            </Form.Group>
          </Col>
          <Col lg={6} md={6}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                onChange={inputChanged}
                value={currentScreen.name || ""}
              />
              <Form.Text className="text-muted">
                A friendly name for this screen.
              </Form.Text>
            </Form.Group>
          </Col>
          <Col lg={2} md={6}></Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group className="sm-3">
              <Form.Label>Show</Form.Label>
              <br />
              <Form.Select
                className="u-flex--1"
                onChange={inputChanged}
                name="mode"
                value={currentScreen.mode || "random"}
              >
                <option value="random" key="0">
                  Random public images
                </option>
                <option value="account" key="1">
                  Random images from my account
                </option>
                <option value="playlist" key="2">
                  Playlist images
                </option>
                <option value={NFT_RANDOM} key="3">
                  Random NFTs
                </option>
                <option value={NFT_COLLECTION} key="4">
                  NFT collection
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <Row className="mb-3">
          <Col>
            {currentScreen.mode == "playlist" && (
              <Form.Group className="sm-3">
                <Form.Label>Playlist:</Form.Label>
                <br />
                {playlistQ.data && (
                  <Form.Select
                    className="u-flex--1"
                    onChange={inputChanged}
                    name="playlist"
                    value={currentScreen.playlist || ""}
                    disabled={playlistQ.data.length == 0}
                  >
                    <option value="" key="none">
                      {playlistQ.data.length == 0
                        ? "-- no playlists defined --"
                        : "-- none --"}
                    </option>
                    {_.map(playlistQ.data, (playlist) => (
                      <option value={playlist.id} key={playlist.id}>
                        {playlist.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Form.Group>
            )}
            {currentScreen.mode == NFT_COLLECTION && (
              <Form.Group className="sm-3">
                <Form.Label>NFT collection:</Form.Label>
                <br />
                {nftQ.data && (
                  <Form.Select
                    className="u-flex--1"
                    onChange={inputChanged}
                    name={NFT_COLLECTION}
                    value={currentScreen.nft_collection || ""}
                    disabled={nftQ.data.length == 0}
                  >
                    <option value="" key="none">
                      {nftQ.data.length == 0
                        ? "-- no nfts defined --"
                        : "-- none --"}
                    </option>
                    {_.map(nftQ.data, (nft) => (
                      <option value={nft.id} key={nft.id}>
                        {nft.name} ({nft.width}x{nft.height}px)
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Form.Group>
            )}
          </Col>
          <Col></Col>
        </Row>
        <Row className="mb-3">
          <Col md={6} lg={3}>
            <Form.Group className="sm-3" controlId="save">
              <Button
                variant="primary"
                type="submit"
                disabled={saveOrUpdate.isLoading}
              >
                Save screen
              </Button>
              {saveOrUpdate.isLoading && <div>Saving...</div>}
            </Form.Group>
          </Col>
          <Col md={6} lg={3}>
            <Form.Group>
              <Button
                variant="warning"
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmModalVisible(true);
                }}
              >
                Delete screen
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <ConfirmationModal
        show={confirmModalVisible}
        onAccept={() => {
          setConfirmModalVisible(false);
          deleteCurrentConfig();
        }}
        onDecline={() => setConfirmModalVisible(false)}
        body={`the screen ${screen.name}`}
      />{" "}
    </div>
  ) : (
    <></>
  );

  return <div>{body}</div>;
}
