import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

export function useUpdateManyMutation({
  onSuccess,
  parentKey,
  mutationFn,
  returnProp,
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: (data) => {
      console.log("onSuccess", { data });
      const { success, [returnProp]: updatedData, message } = data;
      if (!success) throw new Error(message);

      queryClient.setQueryData(parentKey, (existingData) => {
        const rData = [...existingData];
        _.each(updatedData, data=> {
          const existingIndex = rData.findIndex(
          (obj) => obj.id == data.id,
          );
          if (existingIndex >= 0) {
            rData.splice(existingIndex, 1, data);
          } else {
            rData.push(data)
          }
        })
        return rData
      });
      onSuccess(updatedData);
    },
  });
}
