import { Card, Row, Alert } from "react-bootstrap";
import { ImageManager } from "_components/ImageManager";
import { Col } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { useImageActions } from "_actions";
import { SpinnerWithMessage } from "_components/SpinnerWithMessage";
import { useState } from "react";
export { Moderation };

function Moderation() {
  const [page, setPage] = useState(0);
  const imageActions = useImageActions();
  const imageQuery = useQuery({
    queryKey: ["images", { moderated: false, page }],
    queryFn: () => imageActions.getAllUnmoderated(page),
    keepPreviousData: true,
  });

  const { isLoading, error, data } = imageQuery;

  const nextPage = function () {
    setPage(page + 1);
  };
  return (
    <div>
      <Row className="justify-content-md-center">
        <Col md={12}>
          <Card className="mb-3 p-3">
            {data?.length > 0 && (
              <ImageManager
                mode="moderation"
                images={data}
                updateHandler={nextPage}
              ></ImageManager>
            )}
            {data?.length == 0 && <div>There are no images to moderate.</div>}
            {isLoading && <SpinnerWithMessage message={"loading"} />}
          </Card>
          {error && (
            <Alert variant="warning">
              An error occurred gathering images: {imageQuery.error.toString()}
            </Alert>
          )}
        </Col>
      </Row>
    </div>
  );
}
