import _ from "lodash";
import { Button, Col, Form, Row } from "react-bootstrap";
//import { ImageSorter } from "./ImageSorter";
import { ConfirmationModal } from "_components/ConfirmationModal";
import { useEffect, useState } from "react";

import { ChromePicker } from "react-color";
import {
  useImageActions,
  usePlaylistActions,
  useScreenActions,
} from "_actions";
import { ImageSorter } from "./ImageSorter";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useDeleteMutation, useUpdateMutation } from "_helpers";

// import {
//  deletePlaylistFromServer,
//   saveClientsToServer,
//   savePlaylistsToServer,
// } from "../utils/Actions";

export function PlaylistConfigForm({ playlist }) {
  const [currentPlaylist, setCurrentPlaylist] = useState(null);

  const playlistActions = usePlaylistActions();
  const screenActions = useScreenActions();
  const imageActions = useImageActions();

  // update as required if input playlist changes
  useEffect(() => setCurrentPlaylist(playlist), [playlist]);

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  //const [clientList] = useState(screens);
  const clientQ = useQuery({
    queryKey: ["screens"],
    queryFn: screenActions.getAll,
  });
  const imageQ = useQuery({
    queryKey: ["images"],
    queryFn: imageActions.getAll,
  });
  const publicImageQ = useQuery({
    queryKey: ["images", "public"],
    queryFn: imageActions.getPublic,
  });

  const saveOrUpdate = useUpdateMutation({
    mutationFn: playlistActions.saveOrUpdate,
    returnProp: "playlist",
    parentKey: ["playlists"],
    childKey: ["playlists", { id: playlist.id || "new" }],
    onSuccess: (id) => navigate(`/playlists/${id}`),
    isNew: _.isEmpty(playlist.id),
  });

  const updateScreen = useUpdateMutation({
    mutationFn: screenActions.saveOrUpdate,
    returnProp: "screen",
    parentKey: ["screens"],
    childKey: ["screens", { id: screen.id || "new" }],
    onSuccess: (id) => {console.log(`updateScreen got back ${id}`)},
    // set this to use the returned id value for the updateQuery
    // becuase we can't know it in advance
    isNew: true,
  });

  const assignPlaylistToScreen = (screenId) => {
    if (!screenId) return;
    const targetScreen = clientQ.data?.find(screen => screen.id == screenId);
    const updatedScreen = {...targetScreen, playlist: currentPlaylist.id, mode: 'playlist'};
    updateScreen.mutate(updatedScreen);
  }

  const deleteMutator = useDeleteMutation({
    mutationFn: (playlist) => playlistActions.deleteOne(playlist),
    returnProp: "playlist",
    parentKey: ["playlists"],
    childKey: ["playlists", { id: playlist.id }],
    onSuccess: () => navigate("/playlists"),
    isNew: _.isEmpty(playlist.id),
  });

  // const deleteMut = useMutation({
  //   mutationFn: (playlist) => {
  //     return playlistActions.deleteOne(playlist);
  //   },
  //   onSuccess: () => navigate(`/playlists`)
  // })

  const navigate = useNavigate();

  const handleColorChange = (color) => {
    inputChanged({
      target: { type: "color", name: "backgroundColor", value: color.hex },
    });
  };

  const inputChanged = function (e) {
    let newValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    newValue = e.target.type === "number" ? parseInt(newValue) : newValue;
    const newPlaylist = _.defaults(
      { [e.target.name]: newValue },
      { ...currentPlaylist },
    );
    setCurrentPlaylist(newPlaylist);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveOrUpdate.mutate(currentPlaylist);
    // playlistActions.saveOrUpdate(playlist).then((result) => {
    //   if (result.success) return navigate(`/playlists/${result.playlist.id}`);
    // });
  };

  const deleteCurrentConfig = () => {
    deleteMutator.mutate(currentPlaylist);
    // playlistActions
    //   .deleteOne(playlist)
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .then((res) => {
    //     console.log("deleteCurrentConfig", { res });
    //     return navigate("/playlists");
    //   });
  };

  const imageSelectionChanged = (imageIdList) => {
    setCurrentPlaylist({ ...currentPlaylist, images: imageIdList });
  };

  //console.log({ playlist: currentPlaylist });
  // inject playlist statuses into playlist

  const body = !_.isEmpty(currentPlaylist) ? (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col lg={6} md={6}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                onChange={inputChanged}
                value={currentPlaylist.name}
              />
            </Form.Group>
            <Form.Group controlId="imagesetId" className="mb-3">
              <Form.Label>playlist ID</Form.Label>
              <Form.Control
                name="id"
                onChange={inputChanged}
                type="text"
                value={currentPlaylist.id || ""}
                disabled
              />
             <Form.Text className="text-muted">
                sequential counter, locked.
              </Form.Text> 
            </Form.Group>
            <Form.Group controlId="duration" className="mb-3">
              <Form.Label>Image duration</Form.Label>
              <Form.Control
                name="duration"
                onChange={inputChanged}
                type="number"
                value={currentPlaylist.duration}
              />
              <Form.Text className="text-muted">
                Time each image displays for (seconds).
              </Form.Text>
            </Form.Group>
            <Form.Group as={Row}  className="sm-3">
              <Form.Label>Assign this playlist to a screen:</Form.Label>
              <Col>
              <Form.Select
                className="u-flex--1"
                onChange={inputChanged}
                name="targetClientId"
                value={currentPlaylist.targetClientId}
                >
                <option value="" key="0">
                  -- none --
                </option>
                {_.map(clientQ.data || [], (client, index) => (
                  <option value={client.id} key={index + 1}>
                    {client.name}
                  </option>
                ))}
              </Form.Select>
                </Col>
              <Col>
              <Button
                variant="primary"
                disabled={updateScreen.isLoading}
                onClick={() => assignPlaylistToScreen(currentPlaylist.targetClientId)}
              >
                Assign
              </Button>
              </Col>
              <Col>{updateScreen.isSuccess && <div>screen updated</div>}</Col>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="sm-3" controlId="colorPicker">
              <Form.Label>Background colour</Form.Label>
              <ChromePicker
                alpha={false}
                color={currentPlaylist.backgroundColor || "#000000"}
                onChange={handleColorChange}
              />
              <Form.Text className="text-muted">
                Seen behind transparent images, if &apos;transparent&apos; is unselected on the client screen.
              </Form.Text>
            </Form.Group>
          </Col>
          {/* <Col lg={2} md={6}>
            <Form.Group controlId="brightness">
              <Form.Label>Pixel brightness</Form.Label>
              <Form.Control
                name="brightness"
                onChange={inputChanged}
                type="number"
                value={playlist.brightness}
              />
              <Form.Text className="text-muted">1-255</Form.Text>
            </Form.Group>
          </Col> */}
        </Row>
        <Row className="mb-3">
          <Col>

          </Col>
        
        </Row>
        <Row className="mb-3">
          <Col md={6} lg={3}>
            <Form.Group className="sm-3" controlId="save">
              <Button
                variant="primary"
                type="submit"
                disabled={saveOrUpdate.isLoading}
              >
                Save playlist
              </Button>
              {saveOrUpdate.isLoading && <div>saving</div>}
            </Form.Group>
          </Col>
          <Col md={6} lg={3}>
            {currentPlaylist.id && (
              <Form.Group>
                <Button
                  variant="warning"
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmModalVisible(true);
                  }}
                >
                  Delete playlist
                </Button>
              </Form.Group>
            )}
          </Col>
        </Row>

        {
          <ImageSorter
            selectedImageIds={currentPlaylist.images}
            images={imageQ.data || []}
            publicImages={publicImageQ.data || []}
            changeCallback={imageSelectionChanged}
            backgroundColor={currentPlaylist.backgroundColor || "#000000"}
          />
        }
      </Form>
      <ConfirmationModal
        show={confirmModalVisible}
        onAccept={() => {
          setConfirmModalVisible(false);
          deleteCurrentConfig();
        }}
        onDecline={() => setConfirmModalVisible(false)}
        body={`the playlist ${currentPlaylist.name}`}
      />
    </div>
  ) : (
    <div>Choose a playlist from the list to configure it.</div>
  );

  return <div>{body}</div>;
}
