import { useRecoilValue } from "recoil";
import { NavLink } from "react-router-dom";

import { authAtom } from "_state";
import { usePlaylistActions, useScreenActions } from "_actions";
import { useImageActions } from "_actions";
import { useQuery } from "@tanstack/react-query";
import { QueryList } from "_components/QueryList";
import { QueryIconList } from "_components/QueryIconList";
import { Card, Col, Container, Row } from "react-bootstrap";

export { LoggedInHome };

function LoggedInHome() {
  const auth = useRecoilValue(authAtom);
  const playlistActions = usePlaylistActions();
  const imageActions = useImageActions();
  const screenActions = useScreenActions();

  // useEffect(() => {
  //   playlistActions.getAll().then(setFetchError);
  //   screenActions.getAll().then(setFetchError);
  //   imageActions.getAll().then(setFetchError);
  // }, []);
  const playlistQuery = useQuery({
    queryKey: ["playlists"],
    queryFn: playlistActions.getAll,
  });
  const screenQuery = useQuery({
    queryKey: ["screens"],
    queryFn: screenActions.getAll,
  });
  const imageQuery = useQuery({
    queryKey: ["images"],
    queryFn: imageActions.getAll,
  });

  return (
    <div>
      <Container>
        <Row>
          <h1>Hi {auth?.name}!</h1>
        </Row>
        <Row>
          <Col sm="8">
            <Card className="h-100">
              <Card.Header>
                <NavLink
                  className="nav-link fw-bold fs-4"
                  exact={true}
                  to="/images"
                >
                  Images
                </NavLink>
              </Card.Header>
              <Card.Body>
                <h6 className="card-subtitle mb-2 text-muted">
                  Upload and share
                </h6>
                <p className="card-text">Upload images, edit metadata.</p>
                <QueryIconList
                  query={imageQuery}
                  iconAttr="thumb"
                  emptyMessage="No images uploaded to your account yet."
                ></QueryIconList>
                <NavLink exact={true} to="/images" className="card-link">
                  Manage
                </NavLink>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="4">
            <Card className=" mb-3">
              <Card.Header>
                <NavLink
                  className="nav-link fw-bold fs-4"
                  exact={true}
                  to="/playlists"
                >
                  Playlists
                </NavLink>
              </Card.Header>
              <Card.Body>
                <h6 className="card-subtitle mb-2 text-muted">
                  Sequences of images to show
                </h6>
                <p className="card-text">
                  Create and edit playlists of images to show on your screen or
                  share.
                </p>
                <QueryList
                  query={playlistQuery}
                  emptyMessage="no playlists found"
                  baseURL="/playlists"
                ></QueryList>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Header>
                <NavLink
                  className="nav-link fw-bold fs-4"
                  exact={true}
                  to="/screens"
                >
                  Screens
                </NavLink>
              </Card.Header>
              <Card.Body>
                <h6 className="card-subtitle mb-2 text-muted">
                  Places to show your images.
                </h6>
                <p className="card-text">Register and manage your screens.</p>
                <QueryList
                  query={screenQuery}
                  emptyMessage="No screens associated with your account yet."
                  baseURL="/screens"
                ></QueryList>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
