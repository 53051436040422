import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { authAtom } from "_state";
import { useEffect } from "react";

export { PrivateRoute, AdminRoute };

function PrivateRoute({ component: Component, ...props }) {
  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth) navigate("/");
  });
  return <Component {...props} />;
}

function AdminRoute({ component: Component, ...props }) {
  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();
  useEffect(() => {
    console.log('AdminRoute', {auth})
    if (!auth) navigate("/");
  });
  return <Component {...props} />;
}
