//import '../css/Image.scss'

import { useScreenActions } from "_actions";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { ScreenConfigForm } from "./ScreenConfigForm";
import { useQuery } from "@tanstack/react-query";
import { QueryList } from "_components/QueryList";
import _ from "lodash";

export { Screens };

function Screens() {
  // get an id from the URL
  const { selectedId } = useParams();
  const { getAll, getOne, generateNew } = useScreenActions();
  //const screens = useRecoilValue(screensList);
  const screenQ = useQuery({ queryKey: ["screens"], queryFn: getAll });
  const hasLegitId = !_.isEmpty(selectedId) && selectedId != "new";
  const singleScreenQ = useQuery({
    queryKey: ["screens", { id: selectedId }],
    queryFn: () => getOne(selectedId),
    enabled: hasLegitId,
  });

  // const [currentScreen, setCurrentScreen] = useState(null);

  const emptyScreen = generateNew();
  // if (selectedId === "new") {
  // }

  //  const [fetchError] = useState(null);

  // useEffect(() => {
  //   if (selectedId) {
  //     if (selectedId === "new") {
  //       const emptyScreen = generateNew();
  //       console.log({ emptyScreen });
  //       setCurrentScreen(emptyScreen);
  //     } else {
  //       setCurrentScreen(getById(selectedId));
  //     }
  //   } else {
  //     setCurrentScreen(null);
  //   }
  // }, [selectedId, screens]);

  // function screenSelected(id) {
  //   console.log("screenSelected", id);
  //   if (!id) {
  //     const newPlaylist = generateNew();
  //     console.log({ newPlaylist });
  //     setCurrentScreen(newPlaylist);
  //   } else {
  //     setCurrentScreen(getById(id));
  //   }
  // }

  return (
    <div>
      <Row className="justify-content-md-center">
        <Col md={12}>
          <Card className="mb-3">
            <Card.Header>
              <Card.Title as="h5">Screens</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm={3}>
                  <QueryList
                    className="mb-3"
                    header="Your screens"
                    query={screenQ}
                    baseURL="/screens"
                    emptyMessage="No screens registered."
                  />

                  <Link className="btn btn-primary" to={`/screens/new`}>
                    Add New
                  </Link>
                </Col>
                <Col>
                  {(singleScreenQ.isSuccess || selectedId == "new") ? (
                    <ScreenConfigForm
                      screen={hasLegitId ? singleScreenQ.data : emptyScreen}
                    />
                  ): (
                    <><Card><Card.Header>What&apos;s a screen?</Card.Header><Card.Body>A screen is a WLED client that is set up to contact this server and request images. There&apos;s not a lot of setup to do here, in terms of configuring the screen; you do that in WLED when setting up your 2D matrix. Each request for an image includes the width and height of the matrix. </Card.Body></Card>
    <div className='mt-3'>Choose a screen from the list to configure it.</div></>
                  )}
                  {/* {!currentScreen && <div>Choose a screen to begin</div>} */}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
