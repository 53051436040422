import { useRecoilState } from "recoil";
import { useFetchWrapper } from "_helpers";
import { emptyPlaylist, playlistsList } from "_state";
import _ from "lodash";

export { usePlaylistActions };

function usePlaylistActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/playlist`;
  const fetchWrapper = useFetchWrapper();
  const [playlists] = useRecoilState(playlistsList);

  const mapPlaylist = (rawPlaylist) => ({
    ...rawPlaylist,
    images: _.chain(rawPlaylist.images).sortBy("index").map("id").value(),
  });

  return {
    getById,
    getAll,
    saveOrUpdate,
    generateNew,
    deleteOne,
    getOne,
  };

  function generateNew() {
    return emptyPlaylist();
  }

  function getById(id) {
    return playlists.find((playlist) => playlist.id === id);
  }

  function getAll() {
    return fetchWrapper
      .get(`${baseUrl}/all`)
      .then((result) => {
        //setPlaylists(result.playlists.map(mapPlaylist)))
        if (result.success) {
          return result.playlists.map(mapPlaylist);
        } else {
          throw Error("server was unhappy", result.message);
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  function getOne(id) {
    return fetchWrapper
      .get(`${baseUrl}/${id}`)
      .then((result) => {
        //setPlaylists(result.playlists.map(mapPlaylist)))
        if (result.success) {
          const playlist = mapPlaylist(result.playlist); 
          return playlist;
        } else {
          throw Error("server was unhappy", result.message);
        }
      })
      .catch((error) => error);
  }

  function deleteOne(playlist) {
    if (!playlist?.id) return Promise.reject(new Error("empty playlist id"));
    return fetchWrapper
      .delete(`${baseUrl}/delete/${playlist.id}`)
      .then((result) => {
        const { success } = result;
        if (success) {
          return result;
        } else {
          throw Error("server was unhappy", result.message);
        }
      })
      .catch((error) => error);
  }

  function saveOrUpdate(playlist) {
    const action = playlist.id ? "update" : "save";
    return fetchWrapper
      .post(`${baseUrl}/${action}`, playlist, { body: "json" })
      .then((result) => {
        const { success, message, playlist: rawPlaylist } = result;
        const playlist = mapPlaylist(rawPlaylist);
        if (success) {
          // splice returned playlist to the list at existing position
          // const newPlaylists = [...playlists];
          // const index = playlists.findIndex((item) => item.id === playlist.id);
          // if (index > -1) {
          //   newPlaylists.splice(index, 1, playlist);
          // } else {
          //   newPlaylists.push(playlist);
          // }
          // console.log("saveOrUpdate", { newPlaylists });
          // setPlaylists(newPlaylists);
          return { success, message: "playlist saved", playlist };
          // return playlist;
          //{ success: true, message: "playlist saved", playlist };
        } else {
          throw Error(`something went wrong: ${message}`);
        }
      });
  }
}
