import { PlaylistConfigForm } from "./PlaylistConfigForm";
import { useEffect, useState } from "react";

function PlaylistEditor({ playlist }) {
  console.log("PlaylistEditor", playlist?.id);

  const [selectedPlaylist, setPlaylist] = useState(playlist);

  useEffect(() => {
    setPlaylist(playlist);
  }, [playlist]);

  return (
    <div>
      {playlist ? (
        <>
          <h3>{playlist.name || "new playlist"}</h3>
          <PlaylistConfigForm playlist={selectedPlaylist} />
        </>
      ) : (
        <div>Playlist &lsquo;{playlist.id}&lsquo; not found.</div>
      )}
    </div>
  );
}

export { PlaylistEditor };
