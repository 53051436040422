import { useTagSearch } from "_helpers/hooks/use-tag-search-query";
import { useState } from "react";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";



export function TagTypeahead({form, setForm, local = false, localTags=[], allowNew=false }) {
  
  const [searchTerm, setSearchTerm] = useState('')
  
  const tagQuery = useTagSearch(searchTerm);
  
  const handleSearch = (term) => {
    if (term.length < 3) return;
    setSearchTerm(term);
  }

  const remapData = () => {
    return (tagQuery.data || []).map(value => ({label: value, value}));
  } 

  // const renderToken= (option, props, index) => {
  //   return <TagTypeaheadToken option={option} index={index} {...props} />
  // }


  if (local) return <Typeahead
      id="typeahead"
      multiple
      allowNew={allowNew}
      newSelectionPrefix="Add a new tag: "
      onChange={(selected) => setForm({ ...form, tags: selected })}
      selected={form.tags || []}
      isLoading={false}
      minLength={1}
      placeholder="type one or more tags"
      options={localTags ||[]}
   //   renderToken={renderToken}
    />

return <AsyncTypeahead 
  id="typeahead"
  multiple
  allowNew={allowNew}
  newSelectionPrefix="Add a new tag: "
  onChange={(selected) => setForm({ ...form, tags: selected })}
  selected={form.tags || []}
  onSearch={handleSearch}
  isLoading={tagQuery.isFetching}
  minLength={3}
  options={remapData()}
 //  renderToken={renderToken}
/>

}