import _ from 'lodash';

export function useImageFilter(formState) {
  return (image) => {

      const isNSFW = formState.isNSFW > -1
        ? image.isNSFW == formState.isNSFW
        : true;

      const isPublic = formState.isPublic > -1
        ? image.isPublic == formState.isPublic
        : true;
      const matchesTag = formState.tags?.length ?
      _.intersection(image.tags, formState.tags)?.length > 0
      : true;
      return isPublic && isNSFW && matchesTag;
  }
}