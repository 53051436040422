import { Button, Modal } from "react-bootstrap";

export function ConfirmationModal({show, body, onAccept, onDecline}) {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete
          <br />
          {body}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onAccept}>Yes</Button>
        <Button onClick={onDecline}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}
