import { useRecoilValue } from "recoil";
import { authAtom } from "_state";
import PublicHome from "./PublicHome";
import { LoggedInHome } from "./LoggedInHome";

export { Home };

function Home() {
  const auth = useRecoilValue(authAtom);
    console.log({auth});
    if (!auth) return <PublicHome/>
    return  <LoggedInHome/>
}
