import { Link } from "react-router-dom";

export { QueryList };
//import { useQuery } from "@tanstack/react-query";

function QueryList({ header, query, click, className, baseURL, emptyMessage }) {
  // const  = props;
  //  const query = useQuery();
  return (
    <div className={className}>
      {header ? <h6>{header}</h6> : ""}
      {query.data?.length > 0 && !query.isFetching && (
        <div className="list-group">
          {query.data.map((item) => {
            if (baseURL) {
              return (
                <Link
                  className="list-group-item list-group-item-action"
                  key={item.id}
                  to={`${baseURL}/${item.id}`}
                >
                  {item.name}
                </Link>
              );
            } else {
              return (
                <a
                  className="list-group-item list-group-item-action"
                  key={item.id}
                  href={baseURL && `${baseURL}/${item.id}`}
                  onClick={!baseURL && click ? () => click(item.id) : undefined}
                >
                  {item.name}
                </a>
              );
            }
          })}
        </div>
      )}

      {query.data?.length === 0 && !query.isFetching && emptyMessage}
      {query.isFetching && (
        <div className="spinner-border spinner-border-sm"></div>
      )}
      {query.isError && <p className="text-danger">{query.error.toString()}</p>}
    </div>
  );
}
