import { useFetchWrapper } from "_helpers";
import _ from "lodash";

export { useImageActions };

function useImageActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/image`;
  const fetchWrapper = useFetchWrapper();

  return {
    getAll,
    getAllUnmoderated,
    getPublic,
    getRandom,
    uploadOne,
    uploadAll,
    update,
    updateMany,
    deleteMany,
  };

  function getAll() {
    return fetchWrapper.get(`${baseUrl}/all`).then((result) => {
      if (result.success) return result.images;
      throw new Error("image fetch failed");
    });
    //   .catch((error) => throw error);
  }

  function getAllUnmoderated(page) {
    return fetchWrapper
      .get(`${baseUrl}/every`, null, { query: [["page", page]] })
      .then((result) => {
        if (result.success) return result.images;
        throw new Error("image moderation fetch failed");
      });
    //   .catch((error) => throw error);
  }

  function getPublic() {
    return fetchWrapper.get(`${baseUrl}/public`).then((result) => {
      if (result.success) return result.images;
      throw new Error("image fetch failed");
    });
    //   .catch((error) => throw error);
  }

  function getRandom() {
    return fetchWrapper.get(`${baseUrl}/random`).then((result) => {
      if (result.success) return result.images;
      throw new Error("image fetch failed");
    });
    //   .catch((error) => throw error);
  }

  function uploadOne(formData) {
    return fetchWrapper
      .post(`${baseUrl}/upload`, formData, { body: "form" })
      .then((result) => {
        const { success, image } = result;
        if (success) {
          // splice image into the list
          return { success: true, message: "image uploaded", image };
        } else {
          // something went wrong
          throw new Error("something went wrong");
        }
      });
  }
  function uploadAll(form, uploadedImages) {
    const promises = uploadedImages.map(({ uid, file }) => {
      const formData = new FormData();
      console.log("uploadAll", { tags: form.tags });
      form.tags.forEach((tag) => {
        formData.append("tags", tag);
      });
      formData.append("isNSFW", form.isNSFW);
      formData.append("isPublic", form.isPublic);
      formData.append("image", file);
      formData.append("attribution", form.attribution);
      formData.append("source", form.source);
      formData.append("sourceUrl", form.sourceUrl);
      return uploadOne(formData).then((result) => {
        return { result, uid };
      });
    });
    return Promise.all(promises).then((results) => {
      return results;
    });
  }

  /*  Update a single image */

  function update({ update, mode }) {
    const image = mode != "moderation" ? _.omit(update, "moderate") : update;
    return fetchWrapper
      .post(`${baseUrl}/update`, image, { body: "json" })
      .then((result) => {
        const { success, image } = result;
        if (success) {
          return { success: true, message: "image saved", image };
        } else {
          throw new Error("something went wrong");
        }
      });
  }

  /* update multiple images */
  function updateMany({ update, data, mode }) {
    const badValues =
      mode != "moderation" ? ["name", "id", "moderate"] : ["name", "id"];
    const cleanedFormData = _.omit(update, badValues);
    const ids = data.map((image) => image.id);
    const updateBundle = { ...cleanedFormData, ids };
    return fetchWrapper
      .post(`${baseUrl}/update-many`, updateBundle, { body: "json" })
      .then((result) => {
        const { success, images, message } = result;
        if (success) {
          return { success: true, message: "images updated", images };
        } else {
          throw new Error(`something went wrong on the server: ${message}`);
        }
      });
  }

  function deleteMany(images) {
    if (_.isEmpty(images))
      return Promise.reject(new Error("empty images array"));
    return fetchWrapper
      .delete(`${baseUrl}/delete-many/`, { images }, { body: "json" })
      .then((result) => {
        const { success } = result;
        if (success) {
          return result;
        } else {
          throw Error("server was unhappy", result.message);
        }
      })
      .catch((error) => error);
  }
}
