import { useRecoilState } from "recoil";

import { history, useFetchWrapper } from "_helpers";
import { authAtom } from "_state";
import { useNavigate } from "react-router-dom";
import Notify from "_helpers/notify";

export { useUserActions };

function useUserActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/auth`;
  const userUrl = `${process.env.REACT_APP_API_URL}/user`;
  const fetchWrapper = useFetchWrapper();
  const [currentAuth, setAuth] = useRecoilState(authAtom);

  const navigate = useNavigate();

  return {
    login,
    logout,
    update,
    register,
  };

  async function register(credentials, setIsLoading) {
    // If any field is missing
    if (
      !credentials.name ||
      !credentials.email ||
      !credentials.password ||
      !credentials.confirmPassword
    ) {
      setIsLoading(false);
      return Notify("Please Fill all the Feilds", "warn");
    }

    // If password and confirm password doesn't match
    if (credentials.password !== credentials.confirmPassword) {
      setIsLoading(false);
      return Notify("Passwords Do Not Match", "warn");
    }

    // If password is less than 8 characters
    if (credentials.password.length < 8) {
      setIsLoading(false);
      return Notify("Password must be at least 8 characters", "warn");
    }
    try {
      // Register user
      const response = await fetch(`${baseUrl}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: credentials.name,
          email: credentials.email,
          password: credentials.password,
          profilePic: null,
        }),
      });
      const data = await response.json();

      if (data.success) {
        localStorage.setItem("user", JSON.stringify(data)); // Save auth details in local storage
        setAuth(data);
        setIsLoading(false);
        navigate("/"); // Go to home page
        return Notify("Your account has been successfully created", "success");
      } else {
        setIsLoading(false);
        return Notify(data.error, "error");
      }
    } catch (error) {
      setIsLoading(false);
      return Notify("Internal server error", "error");
    }
  }

  async function login(email, password) {
    return fetchWrapper
      .post(`${baseUrl}/login`, { email, password }, { body: "json" })
      .then((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user));
        setAuth(user);

        // get return url from location state or default to home page
        const { from } = history.location.state || { from: { pathname: "/" } };
        navigate(from);
      }).catch(
        
        (e) => e
        
      );
  }

  function logout() {
    // remove user from local storage, set auth state to null and redirect to login page
    localStorage.removeItem("user");
    setAuth(null);
    navigate("/login");
  }

  async function update(form, uploadedImages) {
    const file = uploadedImages?.[0]?.file;
    const formData = new FormData();
    formData.append("name", form.name);
    formData.append("bio", form.bio || '');
    formData.append("apiKey", form.apiKey);
    formData.append("isPublic", form.isPublic || false);
    if (file) formData.append("image", file);

    return fetchWrapper
      .post(`${userUrl}/update`, formData, { body: "form" })
      .then((result) => {
        const { success, user : updatedUser} = result;
        if (success) {
          const user = {...updatedUser, token: currentAuth.token};
          localStorage.setItem("user", JSON.stringify(user));
           setAuth(user);
          return { success: true, message: "user updated", user };
        } else {
          // something went wrong
          return { success: false, message: "something went wrong" };
        }
      });
  }
}
