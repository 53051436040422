import { useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Spinner } from "react-bootstrap";

// import IMAGES from "../../assets";
import Notify from "_helpers/notify";

const ResetPassword = () => {
  const [credentials, setCredentials] = useState({
    password: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const { resetToken } = useParams(); // Get "resetToken" from URL parameters

  const handleCredentials = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const passwordResetHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // If any field is missing
    if (!credentials.password || !credentials.confirmPassword) {
      setIsLoading(false);
      return Notify("Please fill all the fields", "warn");
    }

    // If password and confirm password doesn't match
    if (credentials.password !== credentials.confirmPassword) {
      setIsLoading(false);
      return Notify("Passwords do not match", "warn");
    }

    // If password is less than 8 characters
    if (credentials.password.length < 8) {
      setIsLoading(false);
      return Notify("Password must be at least 8 characters", "warn");
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/resetPassword/${resetToken}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: credentials.password,
        }),
      });
      const data = await response.json();

      if (data.success) {
        setIsLoading(false);
        setIsPasswordReset(true);
        return Notify(data.data, "success");
      } else {
        setIsLoading(false);
        return Notify(data.error, "error");
      }
    } catch (error) {
      setIsLoading(false);
      return Notify("Internal server error", "error");
    }
  };

  return (
    <div className="col-md-6 offset-md-3 mt-5">
    <div className="card">
        <h4 className="card-header">{isPasswordReset ? 'Password change' : 'Create new password'}</h4>
        <div className="card-body">
      {isPasswordReset ? (
            <>

            <p className="email__heading text-center fs-2">Password Changed!</p>
            <p className="text-center text-muted fs-5">
              Your password has been changed successfully.
            </p>
            </>
      ) : (
        <Form className="auth__form" onSubmit={passwordResetHandler}>
          <p className="text-muted mb-4">
            Your new password must be different from previous used passwords.
          </p>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label>New password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              tabIndex="1"
              placeholder="Enter new password"
              value={credentials.password}
              onChange={(e) => handleCredentials(e)}
            />
            <Form.Text className="text-muted">
              Must be at least 8 characters.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              tabIndex="2"
              placeholder="Confirm new password"
              value={credentials.confirmPassword}
              onChange={(e) => handleCredentials(e)}
            />
            <Form.Text className="text-muted">
              Both passwords must match.
            </Form.Text>
          </Form.Group>

          <Button
            tabIndex="3"
            variant="success"
            type="submit"
            className="mb-3"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner animation="border" role="status" size="sm" />
            ) : (
              "Reset password"
            )}
          </Button>
        </Form>
      )}
    </div>
    </div>
    </div>
  );
};

export default ResetPassword;
