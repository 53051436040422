import { Button, Card, CardBody, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useImageActions } from "_actions/image.actions";
//import Typeahead from "react-bootstrap-typeahead/types/core/Typeahead";
import _ from "lodash";
import { TagTypeahead } from "./TagTypeahead";
import { useDeleteMutation, useUpdateMutation } from "_helpers";
import { useUpdateManyMutation } from "_helpers/hooks/use-update-many-mutation";
import { tagsToOptions, typeaheadToValues } from "_helpers/typeahead-utils";
import { ConfirmationModal } from "_components/ConfirmationModal";
import { SpinnerWithMessage } from "./SpinnerWithMessage";

const gatherParam = (images, param) => {
  return _.chain(images)
    .map((image) => image[param])
    .flatten()
    .uniq()
    .value();
};

const gatherAllStrings = (images, param) => {
  return _.chain(images)
    .map((image) => image[param])
    .flatten()
    .uniq()
    .value()
    .join(" / ");
};

function ImageManagerForm({ selectedImages, closeHandler, mode }) {
  const imageActions = useImageActions();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const updateOne = useUpdateMutation({
    mutationFn: imageActions.update,
    returnProp: "image",
    parentKey: ["images"],
    childKey: ["image", { id: selectedImages[0].id || "new" }],
    onSuccess: () => closeHandler(),
    isNew: false,
  });

  const updateAll = useUpdateManyMutation({
    mutationFn: imageActions.updateMany,
    parentKey: ["images"],
    onSuccess: () => closeHandler(),
    isNew: false,
  });

  const deleteAll = () => {
    deleteMutator.mutate(selectedImages);
  };

  const deleteMutator = useDeleteMutation({
    mutationFn: (images) => imageActions.deleteMany(images),
    returnProp: "images",
    parentKey: ["images"],
    childKey: null,
    onSuccess: () => closeHandler(),
    isNew: false,
  });

  const [form, setForm] = useState({});

  const setToggle = (property) => {
    return () => {
      setForm({ ...form, [property]: !form[property] });
    };
  };

  const setValue = (property) => {
    return (e) => {
      const newValue = e.target.value;
      setForm({ ...form, [property]: newValue });
    };
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const submittedForm = { ...form, tags: typeaheadToValues(form.tags) };
    if (selectedImages.length == 1) {
      updateOne.mutate({ update: submittedForm, mode });
    } else {
      updateAll.mutate({ update: submittedForm, data: selectedImages, mode });
    }
    // const result = imageActions.update(form);
    // result.then((result) => {
    //   if (result.success) closeHandler();
    // });
  };

  //setTokens(selectedImage.tags || []
  // derive updated state from props
  useEffect(() => {
    if (selectedImages.length == 1) {
      const selectedImage = selectedImages[0];
      const {
        id,
        name,
        isPublic,
        isNSFW,
        tags,
        moderated,
        attribution = "",
        source = "",
        sourceUrl = "",
      } = selectedImage;
      console.log(tags);
      setForm({
        id,
        name,
        isPublic,
        isNSFW,
        tags: tagsToOptions(tags),
        moderated,
        attribution,
        source,
        sourceUrl,
      });
    } else {
      const isPublic = gatherParam(selectedImages, "isPublic") || [];
      const isNSFW = gatherParam(selectedImages, "isNSFW") || [];
      const tags = gatherParam(selectedImages, "tags");
      const moderated = gatherParam(selectedImages, "moderated") || [];
      const attribution = gatherAllStrings(selectedImages, "attribution") || "";
      const source = gatherAllStrings(selectedImages, "source") || "";
      const sourceUrl = gatherAllStrings(selectedImages, "sourceUrl") || "";
      setForm({
        id: "various",
        tags: tagsToOptions(tags),
        isPublic: isPublic[0],
        isNSFW: isNSFW[0],
        moderated: moderated[0],
        attribution,
        source,
        sourceUrl,
      });
    }

    //    const { id, isPublic, isNSFW, tags } = selectedImages;
  }, [selectedImages]);

  const dimensions = _.chain(selectedImages)
    .map((image) => _.pick(image, "width", "height"))
    .reduce((memo, pair) => {
      const existing = memo.find(
        (item) => item.width == pair.width && item.height == pair.height,
      );
      if (!existing) memo.push(pair);
      return memo;
    }, [])
    .value();

  const somethingIsLoading =
    updateOne.isLoading || updateAll.isLoading || deleteMutator.isLoading;

  return (
    <>
      <Form onSubmit={submitForm}>
        <Form.Group controlId="exampleForm.ControlInput1" className="mb-3">
          <Form.Label>Dimensions:</Form.Label>
          <div className="offcanvas-body-dimensions">
            {_.map(dimensions, (dimension) => (
              <div className="offcanvas-body-dimensions-block mr-1">
                {dimension.width}&#8239;x&#8239;{dimension.height}&#8239;px
              </div>
            ))}
          </div>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput2" className="mb-3">
          <Form.Label>Name:</Form.Label>
          <Form.Control
            name="name"
            onChange={setValue("name")}
            value={selectedImages.length > 1 ? "--various--" : form.name}
            disabled={selectedImages.length > 1}
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput3" className="mb-3">
          <Form.Label>Tags:</Form.Label>
          <TagTypeahead form={form} setForm={setForm} allowNew={true} />
        </Form.Group>
        <Form.Group className="mb-3">
          {mode == "moderation" && (
            <>
              <Form.Label>Moderation status</Form.Label>
              <Form.Check
                type="checkbox"
                checked={form.moderated}
                onChange={setToggle("moderated")}
                label="Moderated"
              />
            </>
          )}
          <>
            <Form.Label>Other settings</Form.Label>
            <Form.Check
              type="checkbox"
              checked={form.isPublic}
              onChange={setToggle("isPublic")}
              label="Public"
            />
            <Form.Check
              type="checkbox"
              checked={form.isNSFW}
              onChange={setToggle("isNSFW")}
              label="NSFW"
            />
          </>
        </Form.Group>
        <Card className="mb-3">
          <CardBody>
            <Form.Group controlId="exampleForm.attribution">
              <Form.Label size="sm">Attribution:</Form.Label>
              <Form.Control
                size="sm"
                name="attribution"
                onChange={setValue("attribution")}
                value={form.attribution}
              />
              <Form.Label size="sm">Source:</Form.Label>
              <Form.Control
                size="sm"
                name="source"
                onChange={setValue("source")}
                value={form.source}
              />
              <Form.Label size="sm">Source URL:</Form.Label>
              <Form.Control
                size="sm"
                name="sourceUrl"
                onChange={setValue("sourceUrl")}
                value={form.sourceUrl}
              />
            </Form.Group>
          </CardBody>
        </Card>
        <Form.Group className="mb-3">
          <Button variant="primary" type="submit" disabled={somethingIsLoading}>
            Update
          </Button>
          <Button
            variant="warning"
            className="ml-2"
            onClick={(e) => {
              e.preventDefault();
              setConfirmModalVisible(true);
            }}
            disabled={somethingIsLoading}
          >
            Delete
          </Button>
          {somethingIsLoading && (
            <SpinnerWithMessage
              message={
                deleteMutator.isLoading
                  ? "Delete in progress"
                  : "Updating images"
              }
            />
          )}
        </Form.Group>
      </Form>

      <ConfirmationModal
        show={confirmModalVisible}
        onAccept={() => {
          setConfirmModalVisible(false);
          deleteAll();
        }}
        onDecline={() => setConfirmModalVisible(false)}
        title={"huh?"}
        body={
          selectedImages.length == 1
            ? `the image ${selectedImages[0].name}`
            : `${selectedImages.length} images`
        }
      />
    </>
  );
}

export { ImageManagerForm };
