import { useSortable } from "@dnd-kit/sortable";

export function Sortable(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`, transition
      }
    : undefined;

  return (
    <div
      title={props.alt}
      className="c-draggable"
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {props.children}
    </div>
  );
}
