import { atom, selector } from "recoil";

const emptyScreen = () => {
  return {
    id: null,
    name: "",
    playlist: null,
  };
};

const screenSelector = selector({
  key: "screenSelector",
  get: () => {
    return [];
  },
  set: ({ set }, data) => {
    set(screensList, data);
  },
});

const screensList = atom({
  key: "ScreensList",
  default: screenSelector,
});

export { emptyScreen, screensList };
