import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

export function useDeleteMutation({
  onSuccess,
  parentKey,
  childKey,
  mutationFn,
  returnProp,
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: (data) => {
      console.log("onSuccess", { data });
      const { success, [returnProp]: returnedData, message } = data;
      if (!success) throw new Error(message);
      //queryClient.invalidateQueries(parentKey);
      //queryClient.invalidateQueries(childKey);
      if (childKey) queryClient.removeQueries(childKey);
      queryClient.setQueryData(parentKey, (existingData) => {
        const rData = [...existingData];
        const iterableData = (!_.isArray(returnedData)) ? [returnedData] : returnedData;
        _.each(iterableData, id => { 
          const existingIndex = rData.findIndex(
            (obj) => obj.id == id,
          );
          if (existingIndex >= 0) {
            rData.splice(existingIndex, 1);
          } else {
            console.warn(
              `failed to remove item from ${parentKey[0]} cache`,
              returnedData,
            );
          }
        });
        return rData;
      });

      onSuccess(returnedData);
    },
  });
}
