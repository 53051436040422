import React from 'react';
import {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { App } from './App';

// setup fake backend
//import { fakeBackend } from './_helpers';
//fakeBackend();

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
        <Suspense fallback={<div>Loading...</div>}>
            <App />
            </Suspense>
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('app')
);
