import { atom, selector } from "recoil";

const emptyPlaylist = () => {
  return {
    id: null,
    name: "",
    duration: 10,
    images: [],
    backgroundColor: "#ff00ff",
  };
};

const playlistSelector = selector({
  key: "playlistSelector",
  get: () => {
    return [];
  },
  set: ({ set }, data) => {
    set(playlistsList, data);
  },
});

const playlistsList = atom({
  key: "PlaylistList",
  default: playlistSelector,
});

export { emptyPlaylist, playlistsList };
