//import '../css/Image.scss'

import { usePlaylistActions } from "_actions";
import { Card, Col, Row } from "react-bootstrap";
import { PlaylistEditor } from "./PlaylistEditor";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { QueryList } from "_components/QueryList";
import _ from "lodash";

export { Playlists };

function Playlists() {
  const { selectedId } = useParams();
  const { generateNew, getAll, getOne } = usePlaylistActions();
  const playlistQuery = useQuery({ queryKey: ["playlists"], queryFn: getAll });
  const hasLegitId = !_.isEmpty(selectedId) && selectedId != "new";
  const singlePlaylistQuery = useQuery({
    queryKey: ["playlists", { id: selectedId }],
    queryFn: () => getOne(selectedId),
    enabled: hasLegitId,
  });

//  console.log({ hasLegitId });

//  console.log({ selectedPlayList: singlePlaylistQuery.data });
  const playlistData =
    singlePlaylistQuery.data || (selectedId == "new" && generateNew());
  return (
    <div>
      <Row className="justify-content-md-center">
        <Col md={12}>
          <Card className="mb-3">
            <Card.Header>
              <Card.Title as="h5">Playlists</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm={3}>
                  <QueryList
                    className="mb-3"
                    header="Playlists (click to edit)"
                    query={playlistQuery}
                    baseURL="/playlists"
                    emptyMessage={"no playlists found"}
                  />
                  <Link className="btn btn-primary" to={`/playlists/new`}>
                    Add New
                  </Link>
                </Col>
                <Col>
                  {playlistData && <PlaylistEditor playlist={playlistData} />}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
