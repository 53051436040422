import { Button, Col, Offcanvas, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { ImageManagerForm } from "./ImageManagerForm";
import _ from "lodash";
import { OverlayVisibilityContext } from "_helpers/contexts";
import ImageFilterBar from "./ImageFilterBar";
import { SpinnerWithMessage } from "./SpinnerWithMessage";
import { useImageFilter } from "_helpers/hooks/use-image-filter";

function ImageManager({ mode, images, updateHandler }) {
  const [showEditor, setShowEditor] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [lastSelectedImage, setLastSelectedImage] = useState(null);
  const [filterFormState, setFilterFormState] = useState({
    isPublic: -1,
    isNSFW: -1,
    tags: [],
  });
  const [tags, setTags] = useState([]);

  // derive updated state from props
  useEffect(() => {
    // calculate this on click or risk a render loop
    const allTags = _.chain(images).map("tags").flatten().uniq().value();
    setTags(allTags);
  }, [images]);

  console.log({ updateHandler });

  const handleClose = () => setShowEditor(false);

  const changeSelectionAndEdit = ({ event, image }) => {
    let newSelectedImages = [...selectedImages];
    const imageIndex = selectedImages.indexOf(image);
    const imageAlreadySelected = imageIndex > -1;
    if (imageAlreadySelected) {
      if (event.ctrlKey) {
        newSelectedImages.splice(imageIndex, 1);
      } else {
        // replace with just this image
        newSelectedImages = [image];
      }
    } else {
      if (event.ctrlKey) {
        // add to array
        newSelectedImages.push(image);
      } else if (event.shiftKey) {
        const lastSelectedIndex = images.indexOf(lastSelectedImage);
        const thisImageIndex = images.indexOf(image);
        const [from, to] =
          thisImageIndex < lastSelectedIndex
            ? [thisImageIndex, lastSelectedIndex + 1]
            : [lastSelectedIndex, thisImageIndex + 1];
        const imagesToAdd = images.slice(from, to);
        newSelectedImages = _.uniq([...newSelectedImages, ...imagesToAdd]);
      } else {
        // replace array
        newSelectedImages = [image];
      }
    }
    setLastSelectedImage(image);
    setShowEditor(newSelectedImages.length > 0);
    setSelectedImages(newSelectedImages);
  };

  const imageFilter = useImageFilter(filterFormState);

  return (
    <div>
      <OverlayVisibilityContext.Provider value={showEditor}>
        <h5 className="card-title">
          {mode == "moderation" ? "Image moderation" : "Manage images"}
        </h5>
        <Row>
          <Col>
            <h6 className="card-subtitle mb-3 text-muted">
              Click on image(s) to change tags, privacy settings. Ctrl-click to
              select / deselect multiple, shift-click to select sequences of
              images at once.
            </h6>
          </Col>
        </Row>

        <ImageFilterBar
          passedState={[filterFormState, setFilterFormState]}
          mode={mode}
        />

        <div className="thumbnail-group d-flex flex-wrap">
          {images.filter(imageFilter).map((image) => (
            <div
              className={`c-icon-list-thumbnail ml-2 mb-2 ${
                selectedImages.indexOf(image) > -1 ? "selected" : ""
              }`}
              key={image.id}
              onClick={(event) => changeSelectionAndEdit({ event, image })}
            >
              <div className="media">
                <img src={image.thumb} alt={image.name}></img>
                {/* <div className="media-body">
                                <h5 className="mt-0">{image.name}</h5>
                                                        <a href='#' key={image.id}>Edit</a>
                                </div> */}
              </div>
            </div>
          ))}
          {mode == "moderation" && (
            <Button size="sm" onClick={updateHandler}>
              more
            </Button>
          )}
        </div>
        {!images && <SpinnerWithMessage message="loading images" />}
        <Offcanvas
          show={showEditor}
          onHide={handleClose}
          scroll={true}
          backdrop={false}
        >
          <Offcanvas.Header closeButton className="offcanvas-header-custom">
            {selectedImages.map((image, index) => (
              <div key={index} className="c-icon-list-thumbnail">
                <img
                  src={image?.full}
                  className="offcanvas-header-custom-img"
                  alt="..."
                ></img>
              </div>
            ))}
            <Offcanvas.Title>
              {selectedImages.length == 1
                ? selectedImages[0].name
                : "multiple images"}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {selectedImages.length > 0 && (
              <ImageManagerForm
                /* TODO fix this */
                selectedImages={selectedImages}
                tags={tags}
                closeHandler={handleClose}
                mode={mode}
              />
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </OverlayVisibilityContext.Provider>
    </div>
  );
}

export { ImageManager };
