import { useRecoilState } from "recoil";
import { useFetchWrapper } from "_helpers";
import { nftsList } from "_state";

export { useNftActions };

function useNftActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/nft`;
  const fetchWrapper = useFetchWrapper();
  const [nfts] = useRecoilState(nftsList);

  return {
    getById,
    getAll,
    getOne,
  };

  function getById(id) {
    return nfts.find((nft) => nft.id === id);
  }

  function getAll() {
    return fetchWrapper
      .get(`${baseUrl}/all`)
      .then((result) => {
        if (result.success) {
          return result.nfts;
        } else {
          throw Error("getAll nfts - server was unhappy", result.message);
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  function getOne(id) {
    return fetchWrapper
      .get(`${baseUrl}/${id}`)
      .then((result) => {
        //setPlaylists(result.playlists.map(mapPlaylist)))
        if (result.success) {
          return result.nfs;
        } else {
          throw Error("server was unhappy", result.message);
        }
      })
      .catch((error) => error);
  }
}
