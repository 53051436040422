import { useMutation, useQueryClient } from "@tanstack/react-query";

export function useUpdateMutation({
  onSuccess,
  parentKey,
  childKey,
  mutationFn,
  isNew,
  returnProp,
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: (data) => {
      console.log("onSuccess", { data });
      const { success, [returnProp]: returnedData, message } = data;
      if (!success) throw new Error(message);
      //queryClient.invalidateQueries(parentKey);
      //queryClient.invalidateQueries(childKey);
      let innerChildKey = childKey;
      if (isNew) innerChildKey = [...parentKey, { id: returnedData.id }];
      queryClient.setQueryData(innerChildKey, returnedData);
      queryClient.setQueryData(parentKey, (existingData) => {
        const rData = [...existingData];
        const existingIndex = rData.findIndex(
          (obj) => obj.id == returnedData.id,
        );
        if (existingIndex >= 0) {
          rData.splice(existingIndex, 1, returnedData);
        } else {
          rData.push(returnedData);
        }
        return rData;
      });

      onSuccess(returnedData.id);

    },
  });
}
