import { useTagActions } from "_actions/tag-actions";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { TagTypeahead } from "./TagTypeahead";
import _ from "lodash";

export default function ImageFilterBar({ passedState: [filterState, setFilterState], showPublic, searchFunction, mode }) {


  // function setToggle(param) {
  //   const newForm = { ...filterForm, [param]: !filterForm[param] };
  //   setFilterForm(newForm);
  // }
  const tagActions = useTagActions()

  function setFilterValue({ key, value }) {
    const newForm = { ...filterState };
    if (value == -1) {
      delete newForm[key];
    } else {
      newForm[key] = value;
    }
    setFilterState(newForm);
  }

  const allTags = tagActions.getAll() || [];

  return (
    <Row className="mb-3">
      <Col >
        <InputGroup>
        <InputGroup.Text>Filters</InputGroup.Text>
          {showPublic && (
            <Form.Select
              onChange={(e) =>
                setFilterValue({ key: "isPublic", value: e.target.value })
              }
              value={filterState.isPublic}
            >
              <option value={-1}>Visibility: All</option>
              <option value={1}>Visibility: Public</option>
              <option value={0}>Visibility: Private</option>
            </Form.Select>
          )}{" "}
          <Form.Select
            onChange={(e) =>
              setFilterValue({ key: "isNSFW", value: e.target.value })
            }
            value={filterState.isNSFW}
          >
            <option value={-1}>All images</option>
            <option value={0}>Mature images: safe</option>
            <option value={1}>Mature images: NSFW</option>
          </Form.Select>
          {mode == 'moderation' &&
          <Form.Select
            onChange={(e) =>
              setFilterValue({ key: "moderated", value: e.target.value })
            }
            value={filterState.moderated}
          >
            <option value={-1}>All images</option>
            <option value={0}>moderated</option>
            <option value={1}>unmoderated</option>
          </Form.Select>}

          <TagTypeahead local={_.isEmpty(searchFunction)} form={filterState} setForm={setFilterState} localTags={allTags} allowNew={false}/>

        </InputGroup>
      </Col>
    </Row>
  );
}
