import _ from "lodash";
import { useCallback, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useUserActions } from "_actions";
import { useRecoilValue } from "recoil";
import { authAtom } from "_state";
import { Repeat } from "react-bootstrap-icons";
import niceware from "niceware";

export default function Profile() {
  const user = useRecoilValue(authAtom);

  const [form, setForm] = useState({ ...user });
  // the img tags generated from them
  const [uploadedImages, setuploadedImages] = useState([]);
  const [uploadMessage, setuploadMessage] = useState({
    success: true,
    message: "",
  });
  const userActions = useUserActions();

  //the completed uploads

  //useEffect(() => {});

  const onDrop = useCallback((acceptedFiles) => {
    dealWithDroppedFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 1000000,
    accept: {
      "image/jpeg": [],
      "image/gif": [],
      "image/webp": [],
      "image/png": [],
    },
  });

  const handleSubmit = function (e) {
    e.preventDefault();
    updateProfile();
  };

  const dealWithDroppedFile = async function (rawFileHandles) {
    const promises = _.map(rawFileHandles, (upload) => {
      return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () =>
          resolve({ uid: upload.path, file: upload, uri: reader.result });
        reader.readAsDataURL(upload);
      });
    });
    let result;
    await Promise.all(promises).then((allDataURIs) => {
      result = _.map(allDataURIs, (resultObj, fileIndex) => {
        return {
          uid: resultObj.uid,
          element: (
            <img alt={resultObj.uid} key={fileIndex} src={resultObj.uri} />
          ),
          file: resultObj.file,
        };
      });
    });
    console.log("dealWithUploads", { result });
    setuploadedImages(result);
  };

  const inputChanged = function (e) {
    let newValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    newValue = e.target.type === "number" ? parseInt(newValue) : newValue;
    const updatedUser = _.defaults({ [e.target.name]: newValue }, { ...form });
    setForm(updatedUser);
  };

  const setToggle = (property) => {
    return () => {
      setForm({ ...form, [property]: !form[property] });
    };
  };

  const updateProfile = async () => {
    await userActions
      .update(form, uploadedImages)
      .then((result) => {
        console.log("updateProfile", { result });
        setuploadMessage(result);
        if (result.success) {
          setForm({ ...result.user });
          // clear dropzone
          setuploadedImages([]);
        }
      })
      .catch((error) => {
        console.log({ caught: error });
        setuploadMessage({ success: false, message: error });
      });
  };
  const emptyMessage = _.isEmpty(uploadedImages) ? (
    <p>Drag a profile pic, or click to select a file</p>
  ) : (
    <p></p>
  );

  const regenKey = () => {
    const newKey = niceware.generatePassphrase(6).join("-");
    const newForm = { ...form, apiKey: newKey };
    setForm(newForm);
  };

  console.log({ form });

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <p>Update your profile.</p>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col sm="8">
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="ControlInput1" className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      as="input"
                      name="name"
                      value={form.name}
                      onChange={inputChanged}
                    />
                  </Form.Group>
                  <Form.Group controlId="ControlInput3" className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      as="input"
                      name="name"
                      disabled
                      value={form.email}
                    />
                  </Form.Group>
                  <Form.Group controlId="ControlInput3" className="mb-3">
                    <Form.Label>API key</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="input"
                        name="apiKey"
                        disabled
                        value={form.apiKey}
                      />
                      <Button variant="outline-secondary" onClick={regenKey}>
                        <Repeat />
                      </Button>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      Enter this into WLED to identify requests from your
                      screens.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="ControlTextarea1" className="mb-3">
                    <Form.Label>Bio</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="bio"
                      onChange={inputChanged}
                    >
                      {form.bio}
                    </Form.Control>
                    <Form.Text className="text-muted">
                      An optional bio. <i>Not used yet.</i>
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <Form.Label>Other options</Form.Label>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm="4">
                  <Form.Group controlId="isPublic">
                    <Form.Check
                      type="checkbox"
                      id="isPublic"
                      name="isPublic"
                      checked={form.isPublic}
                      onChange={setToggle("isPublic")}
                      label="Public"
                    />
                    <Form.Text className="text-muted">
                      Allow others to see your bio. <i>Not used yet.</i>
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col sm="4"></Col>
              </Row>
            </Col>
            <Col>
              <div
                className="c-filedropzone c-filedropzone__square"
                {...getRootProps()}
              >
                {form.profilePic ? (
                  <img
                    className="c-filedropzone__current"
                    src={form.profilePic}
                  />
                ) : (
                  <></>
                )}
                <input {...getInputProps()} />
                <div className="c-filedropzone-inner">
                  {isDragActive ? <p>Drop a file here ...</p> : emptyMessage}
                  <div className="upload">
                    {_.map(uploadedImages, "element")}
                  </div>
                </div>
              </div>
              <Form.Text className="text-muted">
                Would appear on your public page. <i>Not used yet.</i>
              </Form.Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="sm-3 u-margin--top-20" controlId="save">
                <div className="mb-3 {uploadMessage.success? 'text-success' : 'text-danger' }">
                  {uploadMessage.message}
                </div>
                <Button variant="primary" type="submit">
                  Update
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
