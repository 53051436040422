import { atom, selector } from "recoil";

const emptyNft = () => {
  return {
    id: null,
    name: "",
    duration: 10,
    images: [],
    backgroundColor: "#ff00ff",
  };
};

const nftSelector = selector({
  key: "nftSelector",
  get: () => {
    return [];
  },
  set: ({ set }, data) => {
    set(nftsList, data);
  },
});

const nftsList = atom({
  key: "NftList",
  default: nftSelector,
});

export { emptyNft, nftsList };
