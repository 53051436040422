import { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Spinner } from "react-bootstrap";

// import { AuthState } from "../../context/AuthProvider";
import { useUserActions } from "_actions";
import { ToastContainer } from "react-toastify";
//import IMAGES from "../../assets";

const Register = () => {
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    profilePic: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  //const [imagePreview, setimagePreview] = useState(IMAGES.user); // Default image for preview

  const userActions = useUserActions();

  const handleCredentials = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const registerHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    return userActions.register(credentials, setIsLoading);
  };

  return (
    <div className="col-md-6 offset-md-3 mt-5">
      <div className="card">
        <h4 className="card-header">Register</h4>
        <div className="card-body">
          <Form className="auth__form" onSubmit={registerHandler}>
            {/* <Form.Group className="mb-3 d-flex justify-content-center">
        <Image
          id="profilePicUpload"
          src={imagePreview}
          alt="Profile image"
          draggable="false"
          roundedCircle
        />
      </Form.Group> */}

            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                tabIndex="1"
                placeholder="Full name"
                value={credentials.name}
                onChange={(e) => handleCredentials(e)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                tabIndex="2"
                placeholder="Enter email"
                value={credentials.email}
                onChange={(e) => handleCredentials(e)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                tabIndex="3"
                placeholder="Password"
                value={credentials.password}
                onChange={(e) => handleCredentials(e)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                tabIndex="4"
                placeholder="Confirm password"
                value={credentials.confirmPassword}
                onChange={(e) => handleCredentials(e)}
              />
            </Form.Group>

            {/* <Form.Group controlId="profilePic" className="mb-3">
        <Form.Label>Upload profile picture</Form.Label>
        <Form.Control
          type="file"
          accept="image/jpeg, image/jpg, image/png"
          name="profilePic"
          tabIndex="5"
          size="sm"
          onChange={(e) => handleProfilePic(e)}
        />
      </Form.Group> */}

            <Button
              tabIndex="6"
              variant="success"
              type="submit"
              className="mb-3"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                "Create Account"
              )}
            </Button>

            <Form.Group className="mb-3 text-center" controlId="register">
              <span>
                Already have an account?&nbsp;
                <Link to="/login" tabIndex="6" className="text-decoration-none">
                  Log in
                </Link>
              </span>
            </Form.Group>
            <ToastContainer />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Register;
