import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./css/App.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { AdminRoute, Nav, PrivateRoute } from "_components";
import { history } from "_helpers";
import { Home } from "home";
import { Images } from "images";
import { Moderation } from "moderation";
import { Playlists } from "playlists";
import { Login } from "login";
import { Screens } from "screens/Screens";
import Profile from "user/Profile";
import Register from "./user/register";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "login/ForgotPassword";
import ResetPassword from "login/ResetPassword";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import BackgroundImage from "_components/background-image";

export { App };

function App() {
  window.Buffer = window.Buffer || require("buffer").Buffer;

  // useEffect(() => {
  //   if (auth) {
  //     playlistActions.getAll().then(setFetchError);
  //     screenActions.getAll().then(setFetchError);
  //     imageActions.getAll().then(setFetchError);
  //     imageActions.getPublic().then(setFetchError);
  //   }
  // }, [auth]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: process.env.REACT_APP_API_URL, // default: true
      },
    },
  });

  return (
    <div className="app-container bg-light">
      <QueryClientProvider client={queryClient} >
      <BackgroundImage/>
        <BrowserRouter location={history.location} history={history}>
          <Nav />
          <div className="container pt-4 pb-4">
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route
                exact={true}
                path="/images"
                element={<PrivateRoute component={Images} />}
              /><Route
                exact={true}
                path="/moderation"
                element={<AdminRoute component={Moderation} />}
              />
              <Route
                path="/playlists/:selectedId"
                element={<PrivateRoute component={Playlists} />}
              />
              <Route
                exact={true}
                path="/playlists"
                element={<PrivateRoute component={Playlists} />}
              />
              <Route
                path="/screens/:selectedId"
                element={<PrivateRoute component={Screens} />}
              />
              <Route
                exact={true}
                path="/screens"
                element={<PrivateRoute component={Screens} />}
              />
              <Route
                exact={true}
                path="/profile"
                element={<PrivateRoute component={Profile} />}
              />
              <Route path="/login" element={<Login />} />
              <Route
                exact={true}
                path="/login/forgotpassword"
                element={<ForgotPassword />}
              />{" "}
              <Route
                exact={true}
                path="/passwordReset/:resetToken"
                element={<ResetPassword />}
              />
              <Route path="/register" element={<Register />} />
              {/* <Redirect from="*" to="/" /> */}
            </Routes>
          </div>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <ToastContainer />
    </div>
  );
}
