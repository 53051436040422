import { useQuery } from "@tanstack/react-query";
import { useFetchWrapper } from "_helpers";
import { useImageActions } from "./image.actions";
import _ from "lodash";

export { useTagActions };

function useTagActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/tag`;
  const fetchWrapper = useFetchWrapper();

  const imageActions = useImageActions();
  const images = useQuery({queryKey: ['images'], queryFn: imageActions.getAll});

  return {
    find,
    getAll
  };

  function getAll() {
    return _.chain(images.data)
    .map('tags')
    .flatten()
    .uniq()
    .value();
  }

  function find(searchString) {
    return fetchWrapper
      .get(`${baseUrl}/find/?q=${searchString}`, null, { body: "form" })
      .then((result) => {
        if (result.success) return (result.tags || [])
        throw new Error(result.message);
      })
      .catch((error) => {
        throw error;
      });
  }
}
