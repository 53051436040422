import { useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useImageActions } from "_actions";
import _ from "lodash";

export default function BackgroundImage(props) {
  const imageActions = useImageActions();

  const imageQuery = useQuery({
    queryKey: ["random"],
    queryFn: imageActions.getRandom,
  });

  const canvasRef = useRef(null);

  const loaded = [];
  const drawMax = 20;
  const maxScale = 4;
  const randomScales = _.range(0, drawMax)
    .map(() => Math.random() * maxScale)
    .sort();
  let canvas;
  let context;

  const draw = (ctx) => {
    const maxX = window.innerWidth;
    const maxY = window.innerHeight;

    if (loaded?.length != imageQuery.data?.length) return;
    canvas.width = maxX;
    canvas.height = maxY;

    //Our first draw
    ctx.fillStyle = "#444";
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    for (let drawCount = 0; drawCount < drawMax; drawCount++) {
      const scale = randomScales[drawCount];
      //     ctx.globalAlpha = scale/drawMax;
      loaded.forEach((img) => {
        const tHeight = img.naturalHeight * scale;
        const tWidth = img.naturalWidth * scale;
        ctx.drawImage(
          img,
          Math.round(Math.random() * maxX - tWidth / 2),
          Math.round(Math.random() * maxY - tHeight / 2),
          tHeight,
          tWidth,
        );
      });
    }
  };

  useEffect(() => {
    canvas = canvasRef.current;
    context = canvas.getContext("2d");
    context.imageSmoothingEnabled = false;

    window.onresize = resize;

    _.each(imageQuery.data, (image) => {
      var img = new Image();
      img.onload = function () {
        img.width = img.naturalWidth * 4;
        img.height = img.naturalHeight * 4;
        loaded.push(img);
        draw(context);
      };
      img.src = image.full;
      //Our draw come here
      return img;
    });
  }, [draw]);

  function resize() {
    draw(context);
  }

  return (
    <div className="background-image">
      <canvas ref={canvasRef} {...props} />
    </div>
  );
}
