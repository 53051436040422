import { Suspense } from "react";
import { Img } from "react-suspense-img";

export { QueryIconList };

function QueryIconList({ query, header, iconAttr, emptyMessage }) {
  return (
    <>
      {header ? <h6>{header}</h6> : ""}
      {!query.isFetching && query.data?.length > 0 && (
        <div className="c-icon-list mb-3">
          {query.data.map((item) => (
            <div key={item.id} className="c-icon-list-thumbnail-outer">
              <div className="c-icon-list-thumbnail">
                <Suspense fallback="?">
                  <Img src={item[iconAttr]} alt="item.name" />
                </Suspense>
                {/* <img
                  href="#"
                  alt={item.name}
                  className="icon"
                  src={item[iconAttr]}
                /> */}
              </div>
            </div>
          ))}
        </div>
      )}
      {query.data?.length === 0 && !query.isFetching && emptyMessage}
      {query.isFetching && (
        <div className="spinner-border spinner-border-sm"></div>
      )}
      {query.isError && <p className="text-danger">{query.error.toString()}</p>}
    </>
  );
}
