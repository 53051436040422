export default function PublicHome() {
  return (
    <>
      <div className="container">
        <h2>Pixel Art Exchange</h2>
        <div className="row gx-5">
          <div className="col-sm-3 mb-5">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Your pixel art, on a wall!</h5>
                <p>
                  This service is designed to be the easiest way to get pixel
                  art imagery to your{" "}
                  <a
                    rel="noreferrer"
                    href="https://kno.wled.ge/"
                    target="_blank"
                  >
                    WLED
                  </a>{" "}
                  pixel matrix. The latest version of WLED supports 2D matrices
                  of LEDs, which allows you to show 2D effects, or images.
                </p>
                <p>
                  It consists of 2 parts:
                  <ul>
                    <li>
                      This server where you can upload images, manage screens
                      and playlists (sequences of images){" "}
                    </li>
                    <li>
                      A custom usermod for WLED that periodically requests
                      images
                    </li>
                  </ul>
                  Optionally, you can share pixel art with other users, allowing
                  them to display your art on their screens. The server takes
                  care of resizing and transcoding images into data, to match
                  the pixel size of the screen they are being sent to.
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-5 mb-5">
            <div className="card">
              <img className="card-img-top" src="https://images.pixelart-exchange.au/uploads/cherries.jpg" alt="An example"/>
              <div className="card-body">
                <p>
                  A 32x32 matrix.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-3 mb-5">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Registration is optional</h5>
                <p>
                  Getting WLED compiled with the{" "}
                  <a
                    rel="noreferrer"
                    href="https://github.com/hughc/wled-pixelart-client"
                    target="_blank"
                  >
                    Usermod
                  </a>{" "}
                  is enough to get started.
                </p>
                <ul>
                  <li>
                    If you get the Usermod running and point it at this service,
                    then you will get random public images returned
                  </li>
                  <li>
                    if your register, you can get a secret key that identifies
                    you, and get random images you have uploaded
                  </li>
                  <li>
                    if your set a unique id for your screen, you can assign a
                    playlist of images to it, letting you control sequencing,
                    timing etc
                  </li>
                </ul>
                <p>
                  If you don&apos;t want your WLED matrix talking to a random
                  service on the net, code is available to{" "}
                  <a
                    rel="noreferrer"
                    href="https://github.com/hughc/pixel-art-server"
                    target="_blank"
                  >
                    run your own server
                  </a>{" "}
                  locally
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm"></div>
        </div>
      </div>
    </>
  );
}
