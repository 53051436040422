import { useQuery } from "@tanstack/react-query";
import { useTagActions } from "_actions/tag-actions";

export function useTagSearch(term) {
  const actions = useTagActions()
  const query = useQuery({
    queryKey: ['tag-search', term],
    queryFn: () => {
      return actions.find(term)
    },
   enabled: term.length>2

});

return query;

}